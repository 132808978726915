@font-face {
  font-family: "Open Sans";
  src: url("opensans-light-webfont.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("opensans-regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("opensans-semibold-webfont.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("opensans-bold-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
